import React, {useState} from "react";

type WalletInputProps = {
    handleInputChange: (key: number, value: string) => void;
    manualKey: number;
}

export default function WalletInput(props: WalletInputProps) {
    const key = props.manualKey;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [inputValue, setInputValue] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(false);

    function isValidEthWallet(inputValue: string): void {
        let regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
        if (!inputValue) setIsValid(false);
        setIsValid(regex.test(inputValue));
    }

    return (
        <div className={"flex items-center justify-center"}>
            <input
                onChange={(event) => {
                    setInputValue(event.target.value);
                    isValidEthWallet(event.target.value);
                    props.handleInputChange(key, event.target.value);
                }}
                placeholder={"0x000000000000000000000000000000000000dEaD"}
                className={`mt-2 h-10 rounded w-[500px] text-blue-950 font-bold text-center ${isValid ? "text-green-700" : "text-red-700"}`}
            />
        </div>
    );
}
import React, {useState} from 'react';
import './App.css';
import WalletInput from "./WalletInput";

const elementValues = [""];

export default function App() {
    const [elements, setElements] = useState<Array<JSX.Element>>(Array.of(<WalletInput key={1} handleInputChange={handleInputChange} manualKey={1} />));
    const [isContractValid, setIsContractValid] = useState<boolean>(true);
    const [contractAddress, setContractAddress] = useState<string>("");
    const [walletCheckResult, setWalletCheckResult] = useState<Array<JSX.Element>>([]);

    function handleInputChange(key: number, value: string): void {
        elementValues[key - 1] = value;
    }

    function isValidEthWallet(inputValue: string): void {
        let regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
        if (!inputValue) setIsContractValid(false);
        setIsContractValid(regex.test(inputValue));
    }

    function checkIsValidEthWallet(inputValue: string): boolean {
        let regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
        if (!inputValue) return false;
        return regex.test(inputValue);
    }

    async function checkWallets(): Promise<void> {
        if (!contractAddress) {
            setWalletCheckResult(Array.of(<p>{"Missing contract address!"}</p>));
            return;
        }

        let elementsToCheck: { element: string; isValid: boolean; amount: number; }[] = [];
        elementValues.map((element, i) => (
            elementsToCheck.push({element: element, isValid: checkIsValidEthWallet(element), amount: 0})
        ));

        let apiDto: {contractAddress: string, walletAddresses: string[]} = {contractAddress: "", walletAddresses: []};

        apiDto.contractAddress = contractAddress;
        let wallets: string[] = [];
        elementsToCheck.filter(element => element.isValid).map(element => (wallets.push(element.element)));
        apiDto.walletAddresses = wallets;

        const result = await fetch("https://delegate-checker.patrinus.io/api/DelegateChecker", {
            method: "POST",
            body: JSON.stringify(apiDto),
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (!result.ok) {
            setWalletCheckResult(Array.of(<p>{"Some kind of error happened"}</p>));
            return;
        }

        let content: {walletAddress: string, amount: number}[] = await result.json();

        let resultAsElement: JSX.Element[] = [];
        content.map((element) => (
            resultAsElement.push(<p>{element.walletAddress + " : " + element.amount}</p>)
        ));

        setWalletCheckResult(resultAsElement);
    }

    return (
    <div className={"bg-blue-950 text-white min-h-screen"}>
        <div className={"pt-10"}>
            <h1 className={"text-2xl font-bold text-white-900 text-center"}>Just a wallet delegation checker</h1>
            <h1 className={"text-xs font-bold text-white-900 text-center pt-5"}>This website does not save any kind of information used here.</h1>
            <h1 className={"text-xs font-bold text-white-900 text-center"}>Disclaimer: This website uses the
                delegate.xyz smart contracts to check delegations but is not in any way connected to it.</h1>
        </div>
        <div className={"mt-20 text-center"}>
            <p className={"text-center font-bold"}>{"NFT contract address:"}</p>
            <input
                placeholder={"0x000000000000000000000000000000000000dEaD"}
                className={`mt-2 h-10 rounded w-[500px] text-blue-950 font-bold text-center ${isContractValid ? "text-green-700" : "text-red-700"}`}
                onChange={(event) => {
                    isValidEthWallet(event.target.value);
                    setContractAddress(event.target.value);
                }}
            />
            <p className={"text-center font-bold pt-10"}>{"Wallet addresses to check:"}</p>
            {elements.map((el) => (
                el
            ))}
            <button
                className={"bg-white text-blue-950 h-10 w-36 mt-10 rounded-2xl font-bold"}
                onClick={() => {
                    setElements([...elements, <WalletInput key={elements.length + 1} handleInputChange={handleInputChange} manualKey={elements.length + 1}/>]);
                }}
            >
                Add more
            </button>
            <button
                className={"bg-white text-blue-950 h-10 w-36 mt-10 rounded-2xl font-bold"}
                onClick={checkWallets}
            >
                Check wallets
            </button>
            <div className={"pt-10"}>
                Output:
                <p>{walletCheckResult}</p>
            </div>
        </div>
    </div>
    );
}